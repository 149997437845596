import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { NavLink } from 'react-router-dom';
import { API_URL } from '../../../../../actions/index';
import { useGlobalContext } from '../../../../GlobalContext';
import { setFormShowroom } from '../../../../../actions/showroom';
import { calculPrice } from '../helpers';
import { fnFormat } from '../../../../../helpers/helpers';

import selectStyle from '../../../../partials/form_fields/selectStyle';
import InputField from '../../../../partials/form_fields/InputField';
import Loader from '../../../../partials/Loader';

import SwitchField from '../../../../partials/form_fields/SwitchField';
import SelectItems from '../../../../partials/form_fields/SelectItems';
import StepContext from '../StepContext';
import SupportContact from '../../../../partials/SupportContact';
import TooltipInfo from '../../../../partials/TooltipInfo';
import PdfEvier from '../../../../../assets/doc/evier.pdf';

const textStorage = `
Cette prestation stockage comprend :<br/>
<ul>
  <li>la prise en charge de vos palettes par notre prestataire devant votre stand le jour du montage vers la zone de stockage</li>
  <li>le retour de votre palette devant votre salon à la fin du salon</li>
</ul>
La zone de stockage sera fermée pendant les horaires du Salon. Pour toute demande pendant Salon, merci de vous rendre à l’accueil. En cas de non réservation d’espace de stockage sur le formulaire d’inscription, toute demande le jour de l’installation sera facturée 100€ / équivalent 80x120. Aucun stockage ne sera autorisé à l’extérieur de votre stand.
`;

export default function ListOptions(props) {
  const [context, dispatch] = useGlobalContext();
  const { showRoomReducer } = context;
  const stepContext = useContext(StepContext);

  const [standiste, setStandiste] = useState({});

  // SWITCH STATE
  const [carpet, setCarpet] = useState(true);
  const [customCarpet, setCustomCarpet] = useState(false);
  // const [ carpetDesc, setCarpetDesc] = useState("");

  const [kit, setKit] = useState(false);
  const [optionsKit, setOptionsKit] = useState([]);
  const [countKit, setCountKit] = useState(null);

  const [power, setPower] = useState(false);
  const [internet, setInternet] = useState(false);

  const [water, setWater] = useState(false);
  const [waterType, setWaterType] = useState(null);

  const [storage, setStorage] = useState(false);
  const [storageSize, setStorageSize] = useState('');
  const pricePerMeter = 50;

  /// ///////

  // ACTIONS
  const _setFormShowroom = (data) => setFormShowroom(dispatch, data);

  /// INIT LISTOPTIONS WITH FORMDATA FROM SHOWROOMREDUCER
  useEffect(() => {
    if (props.isLoading) return;

    // OPTIONS KIT DEPENDING ON DIMENSION
    const { dimension } = showRoomReducer.formData;
    let maxKit = 0;
    if (dimension) {
      maxKit = Math.ceil(dimension / 6);
    }
    let optionsKit = new Array(maxKit);

    if (maxKit) {
      optionsKit = optionsKit.fill(null).map((d, i) => {
        let k = i;
        k++;
        let kit = `${k} kit`;
        kit = k > 1 ? `${kit}s` : kit;
        return { label: kit, value: k };
      });
      setOptionsKit(optionsKit);
    }

    const options = showRoomReducer.formData.option ? showRoomReducer.formData.option : {};

    
    if (!('carpet' in options)) {
      handleChange(true, 'option.carpet')
    } else if (typeof options.carpet === 'boolean') {
      setCarpet(options.carpet); // Sinon, on garde sa valeur existante
    }

    if (typeof options.customCarpet === 'boolean') {
      setCustomCarpet(options.customCarpet);
      // if(options.carpetDesc){
      //   setCarpetDesc(options.carpetDesc)
      // }
    }

    if (typeof options.kit === 'boolean') {
      setKit(options.kit);
      if (options.countKit) {
        const found = optionsKit.find((option) => option.value === options.countKit);
        setCountKit(found);
      }
    }
    if (typeof options.power === 'boolean') {
      setPower(options.power);
    }
    if (typeof options.internet === 'boolean') {
      setInternet(options.internet);
    }
    if (typeof options.water === 'boolean') {
      setWater(options.water);
      if (options.waterType) {
        setWaterType(options.waterType);
      }
    }
    if (typeof options.storage === 'boolean') {
      setStorage(options.storage);
      if (options.storageSize) {
        setStorageSize(options.storageSize);
      }
    }

    if (options.standiste) {
      const dataStandiste = options.standiste;

      setStandiste({
        name: dataStandiste.name ? dataStandiste.name : '',
        email: dataStandiste.email ? dataStandiste.email : '',
        phone: dataStandiste.phone ? dataStandiste.phone : '',
      });
    }
  }, [props.isLoading]);

  function selectKit(val) {
    const updatedData = _.cloneDeep(showRoomReducer.formData);
    updatedData.option.countKit = val.value;
    _setFormShowroom(updatedData);
    setCountKit(val);
  }

  function handleChange(val, field) {
    if (val === '' && field !== 'option.carpetDesc') return;
    const updatedData = _.cloneDeep(showRoomReducer.formData);

    if (field === 'option.carpet') {
      setCarpet(val);
      if (val) {
        setCustomCarpet(false);
        // setCarpetDesc("")
        delete updatedData.customCarpet;
        // delete updatedData.carpetDesc
      }
    } else if (field === 'option.customCarpet') {
      setCustomCarpet(val);
      setCarpet(false);
      delete updatedData.carpet;
      // if(!val){
      //   setCarpetDesc("")
      //   delete updatedData.carpetDesc
      // }
    } else if (field === 'option.carpetDesc') {
      // setCarpetDesc(val)
    } else if (field === 'option.kit') {
      if (!val) {
        setCountKit(null);
        delete updatedData.countKit;
      } else {
        setCountKit(optionsKit[0]);
      }
      setKit(val);
    } else if (field === 'option.power') {
      setPower(val);
    } else if (field === 'option.internet') {
      setInternet(val);
    } else if (field === 'option.water') {
      if (!val) {
        setWaterType(null);
        delete updatedData.waterType;
      }
      setWater(val);
    } else if (field === 'option.storage') {
      setStorage(val);
      if (!val) {
        setStorageSize(0);
        updatedData.option.storageSize = 0;
        // delete updatedData.storageSize
      }
    } else if (field === 'option.storageSize') {
      setStorageSize(val);
    } else if (field === 'option.waterType') {
      setWaterType(val);
    }

    _.set(updatedData, field, val);
    _setFormShowroom(updatedData);
  }

  function renderButtonValidated() {
    let disabled = 'disabled';

    if (stepContext.step1
      && stepContext.step2) {
      disabled = '';
    }

    return (
      <>
      { disabled === 'disabled'
        ? <button className={'disabled'}>Finaliser</button>
        : <NavLink to="/stand/recapitulatif">
          <button>Finaliser</button>
        </NavLink>
      }
      </>
    );
  }

  // With option for water
  // <SwitchField
  //   checked={water}
  //   handleChange={(val) => handleChange(val,"option.water")}
  //   conditional={water ?
  //    <div className="options-water">
  //    <SelectItems
  //      selectedItems={waterType}
  //      uniq={true}
  //      items={["alimentation eau seul", "alimentation eau + évier"]}
  //      handleChange={(val) => handleChange(val,"option.waterType")}
  //    />
  //    { waterType === "alimentation eau + évier" ?
  //      <a href={API_URL+'/static/evier.pdf'} target="_blank" >(détail évier)</a> : null
  //    }
  //    </div> : null}
  // >
  //   <p>
  //     Avez-vous besoin d’une alimentation en eau ? <span className="sub">(supplément 310€)</span>
  //   </p>
  // </SwitchField>

  //   <SwitchField
  //   checked={customCarpet}
  //   handleChange={(val) => handleChange(val,"option.customCarpet")}
  //   conditional={ customCarpet ?
  //     <textarea
  //       rows="5" cols="63"
  //       value={carpetDesc}
  //       onChange={(e) => handleChange(e.target.value,"option.carpetDesc")}
  //     /> : null
  //   }
  // >

  return (
    <div className="list-options">
      <section>
        <div className="container-switch">
          <SwitchField
            checked={carpet}
            handleChange={(val) => handleChange(val, 'option.carpet')}
          >
            <p>
              Avez-vous besoin de moquette aux couleurs du salon ? <span className="sub">(compris dans l’offre)</span><br/>
              <span className="sub">(Bleu pour les fournisseurs « multimédia » et Orange pour les fournisseurs « Maison »)</span>
            </p>
          </SwitchField>
          <SwitchField
              checked={customCarpet}
              handleChange={(val) => handleChange(val, 'option.customCarpet')}
            >
              <p>
                 Apportez vous un sol personnalisé ?
              </p>
            </SwitchField>
          <SwitchField
            checked={kit}
            handleChange={(val) => handleChange(val, 'option.kit')}
            conditional={ kit
              ? <>
              {showRoomReducer.formData.dimension
                ? <Select
                 value={countKit}
                 onChange={selectKit}
                 options={optionsKit}
                 isSearchable={false}
                 styles={selectStyle}
                />
                : <p className="warn">
                  Vous devez indiquer la dimension
                  de l'espace d'exposition à l'étape précédente
                  pour pouvoir selectionner un nombre de kit
               </p>
              }
              </>
              : null
            }
          >
            <p>
              Souhaitez vous disposer du mobilier « kit de base » pour 6m² ? <span className="sub">(compris dans l’offre, 1 table et 2 chaises) </span>
            </p>
          </SwitchField>
        </div>
      </section>
      <section>
        <div className="standiste">
          <p className="section-title">Information sur le standiste</p>
          <div className="container-inputs">
            <InputField
              value={standiste.name ? standiste.name : ''}
              title={'Nom'}
              placeholder={''}
              type={'string'}
              textTransform={'uppercase'}
              handleChange={(val) => handleChange(val, 'option.standiste.name')}
            />
            <InputField
              value={standiste.email ? standiste.email : ''}
              title={'Adresse mail'}
              placeholder={''}
              format={'email'}
              type={'string'}
              handleChange={(val) => handleChange(val, 'option.standiste.email')}
            />
            <InputField
              value={standiste.phone ? standiste.phone : ''}
              title={'Téléphone'}
              placeholder={''}
              type={'string'}
              maskNumber={'## ## ## ## ##'}
              format={'phone'}
              handleChange={(val) => handleChange(val, 'option.standiste.phone')}
            />
          </div>
        </div>
      </section>
      { showRoomReducer.formData
        && <div className="container-total">
          <label>LOCATION D’ESPACE&nbsp;: <span className="total">{fnFormat(calculPrice(showRoomReducer.formData).totalNoOptions)} €</span></label>
        </div>
      }

      <h2 >
        Prestations complémentaires&nbsp;: options facultatives qui seront incluses dans le contrat et la facturation globale de la location d’espace (si validées par vos soins)
      </h2>
      <section>
        <div className="container-switch">
          <SwitchField
            checked={power}
            handleChange={(val) => handleChange(val, 'option.power')}
          >
            <p>
              Avez-vous besoin d'une puissance électrique de 20kVa ? <span className="sub">(supplément 300€)</span><br/>
              <span className="sub">(Puissance mise à disposition : 6 kVa)</span>
            </p>
          </SwitchField>
          <SwitchField
            checked={storage}
            handleChange={(val) => handleChange(val, 'option.storage')}
            conditional={storage
              ? <>
              <InputField
                value={storageSize}
                title={'Espace de stockage'}
                placeholder={''}
                type={'number'}
                handleChange={(val) => handleChange(val, 'option.storageSize')}
              />
              {(showRoomReducer.formData
                && (parseInt(showRoomReducer.formData.option.storageSize) === 0 || !showRoomReducer.formData.option.storageSize)
              )
                && <p className="warn">
                  Veuillez renseigner l'espace de stockage dont vous avez besoin.
               </p>
              }
              <p>
                <span className="sub">
                   Nous dégageons toutes responsabilités en cas de vol et/ou détérioration des produits et/ou matériels stockés dans cet espace. Hors période d'installation et de démontage l'espace ne sera pas accessible (fermé sous clés).
                </span>
              </p>
              {storageSize !== ''
                ? <p><span className="sub">Supplément : {storageSize * pricePerMeter}€</span></p>
                : null
              }
              </>
              : null
            }
          >
            <p>
              Avez-vous besoin d’un espace de stockage ?
              <TooltipInfo html={textStorage}/>
              <span className="sub">({pricePerMeter}€ / équivalent palette 80x120)</span>
            </p>
          </SwitchField>
          <SwitchField
            checked={internet}
            handleChange={(val) => handleChange(val, 'option.internet')}
          >
            <div className='options-wifi'>
              <p>
                Avez-vous besoin d’une connexion internet filaire ? <span className="sub">(300 € la connexion 2Mo)</span>
              </p>
              <ul>
                <li>Une ligne filaire dans votre espace privatif</li>
                <li>VLAN dédié et sécurisé</li>
                <li>Débit symétrique garanti : 2 Mo avec nb max d’utilisateurs/équipements conseillé</li>
                <li>Switch 7 ports disponibles inclus</li>
                <li>Pas de portail de connexion</li>
              </ul>
              <p>Pensez à désactiver le Wifi de vos équipements qui n'utilisent pas le wifi pour vos présentations sur le salon ( ex : imprimantes sans fil ). "Trop de wifi tue le Wifi"</p>
            </div>
          </SwitchField>
        </div>
      </section>
      { showRoomReducer.formData
        && <div className="container-total">
          <label>Total des options complémentaires&nbsp;: <span className="total">{fnFormat(calculPrice(showRoomReducer.formData).totalOptions)} €</span></label>
        </div>
      }
      <div className="container-support">
        <SupportContact pageForm={true}/>
        {renderButtonValidated()}
      </div>
    </div>
  );
}
